import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../models/user.model';
import { AddressbookRecord, CallService } from '../../../core/services';

@Component({
  selector: 'spy-call-button',
  templateUrl: './spy-call-button.component.html',
  styleUrls: [
    '../../styles/contact-list/contact-list.scss',
    './spy-call-button.component.scss'
  ]
})
export class SpyCallButtonComponent implements OnInit {

  @Input() record: AddressbookRecord;
  @Input() users: User[];
  @Input() extension: string;
  @Input() size: number = 40;
  @Input() alternative: boolean = false;
  @Input() disabled: boolean = false;

  @Output() callStarted: EventEmitter<void> = new EventEmitter();
  
  public userToSpy: User = null;
  private number: string;

  constructor(
    private callService: CallService
  ) { }

  ngOnInit(): void {
    if(this.record) {
      this.userToSpy = this.users.find((v) => {
        return `u${v.id}` === this.record.remoteId;
      });
      this.number = this.record.defaultNumber.number;
    }

    if(this.extension){
      this.userToSpy = this.users.find((v) => {
        return String(v.extension_number) === this.extension;
      });
      this.number = this.extension;
    };
  }

  public call(event) {
    event.stopPropagation();
    this.callService.callSpyFromNumber(this.number, this.userToSpy.call_spy_callflow.feature_code);
    this.callStarted.emit();
  }

}
