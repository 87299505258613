import { Subscription } from "rxjs";
import { EndCallOptions } from "../../sipjs/models";
import { SessionWrapper } from "../../sipjs/wrappers";
import { AddressbookRecord, ADDRESSBOOK_TYPES, CrmLookupResponse, APIAddressbookRecord } from "../addressbook/addressbook.model";
import { SessionContainer, SESSION_TYPE } from "../sip";

/**
 * Common interface for call view and small call view
 */
export interface CallModel {
  id: number;
  fullname: string;
  number: string;
  session: SessionContainer; // used to access the session by id
  original: any;
  avatar?: string;
  externalLink?: string; // Most probably crm link
  type?: CALL_TYPES;
  spyMode?: CALL_MODES;
}

export interface IncomingCallElectronModel {
  callId: number;
  fullname: string;
  callee: string;
}

export enum CALL_TYPES {
  NORMAL = 'normal',
  SPY = 'spy'
}

export enum CALL_MODES {
  WHISPER_A = 'whisper_a',
  LISTEN = 'listen',
  NONE = 'none'
}


export class Call {
  public id: number;
  public fullname: string;
  public number: string;
  public sessionContainer: SessionContainer; // used to access the session by id
  public original: AddressbookRecord;
  public avatar?: string;
  public externalLink?: string; // Most probably crm link

  private _type: CALL_TYPES;
  private _spyMode: CALL_MODES = CALL_MODES.NONE;

  private _timerFromCreation: NodeJS.Timer;
  private _timerFromEnstablished: NodeJS.Timer;
  private _elapsedTimeFromCreation: number = 0;
  public elapsedTimeFromEnstablished: number = 0;
  public isTerminating: boolean = false;
  public accepted: boolean = true;

  private changeTypeSubscription: Subscription;


  constructor(model: CallModel) {
    this.id = model.id;
    this.fullname = model.fullname;
    this.number = model.number;
    this.sessionContainer = model.session;
    this.original = model.original;
    this.avatar = model.avatar;
    this.externalLink = model.externalLink;
    this._type = model.type || CALL_TYPES.NORMAL;
    if(this._type === CALL_TYPES.SPY){
      this._spyMode = model.spyMode || CALL_MODES.LISTEN;
    }


    if(this.sessionContainer.session.incoming) this.accepted = false;

    this._timerFromCreation = setInterval(() => {
      this._elapsedTimeFromCreation += 1;
    }, 1000);

    this.changeTypeSubscription = this.sessionContainer.changeTypeListener.subscribe((type: SESSION_TYPE) => {
      switch(type) {
        case SESSION_TYPE.ANSWERED:
          this.startTimer();
          break;
        case SESSION_TYPE.TERMINATED:
          this.isTerminating = true;
          this.dispose();
      }
    });
  }

  public updateCallWithDBContact(contact: AddressbookRecord) {
    const c = contact;
    this.fullname = c.lastName ? `${c.firstName} ${c.lastName}` : c.firstName;
    this.original = c;
    this.updateHistoryRecord();
  }

  public updateCallWithNumber(number: string){
    this.number = number;
  }

  public updateHistoryRecord() {
    const record = this.sessionContainer.callHistoryRecord;
    record.displayName.name.remote = this.fullname;
    record.displayName.number = this.number;
    if(this.original.type){
      record.addressbookType = this.original.type;
    }
    if(this.original.crm_logo) {
      record.crmLogo = this.original.crm_logo;
    }
    if(this.original.CRM) {
      record.crmName = this.original.CRM;
    }
    if(this.original.crm_contact_url) {
      record.crmContactUrl = this.original.crm_contact_url;
    }
    this.sessionContainer.callHistoryRecord = record;
    this.sessionContainer.updateCallHistory(record);
    return this;
  }

  private clearAllInterval() {
    if(this._timerFromCreation) clearInterval(this._timerFromCreation);
    if(this._timerFromEnstablished) clearInterval(this._timerFromEnstablished);
  }


  public dispose() {
    this.clearAllInterval();
    this.changeTypeSubscription.unsubscribe();
  }

  public get isMute() {
    return this.sessionContainer.session.muted;
  }

  public toggleMute() {
    if(this.isMute) {
      this.sessionContainer.session.unmute();
    } else {
      this.sessionContainer.session.mute();
    }
  }

  public get isPaused() {
    return this.sessionContainer.session.held;
  }

  public get incoming() {
    return this.sessionContainer.session.incoming;
  }

  public get type() {
    return this._type;
  }

  public get spyMode() {
    return this._spyMode;
  }

  public changeSpyMode(mode: CALL_MODES) {
    switch(mode){
      case CALL_MODES.LISTEN:
        this.sendDtmf('0');
        this._spyMode = mode;
        break;
      case CALL_MODES.WHISPER_A:
        this.sendDtmf('2');
        this._spyMode = mode;
        break;
    }
  }

  public togglePause() {
    if(this.isPaused) {
      this.sessionContainer.session.unhold();
    } else {
      this.sessionContainer.session.hold();
    }
  }

  public setPause() {
    if(this.isPaused) return;
    this.sessionContainer.session.hold();
  }

  public transfer(target: string | SessionWrapper, domain?: string) {
    this.sessionContainer.session.transfer(target, domain);
  }

  public sendDtmf(tone: string) {
    this.sessionContainer.session.sendDtmf(tone);
  }

  public startTimer() {
    this._timerFromEnstablished = setInterval(() => {
      this.elapsedTimeFromEnstablished += 1;
    }, 1000);
  }

  public hangup(options?: EndCallOptions) {
    this.sessionContainer.session.endCall(options);
    this.dispose();
  }  
}