import { UserActions, saveUsers } from "../actions/user.action";
import { UserStoreState } from "../user.model";

export const initialState: UserStoreState = {
  users: null
}

export function userReducer(
  state = initialState,
  actions: UserActions
): UserStoreState {
  switch(actions.type) {
    case saveUsers.type: {
      return {
        ...state,
        users: actions.users
      }
    }
    default: {
      return state;
    }
  }
}