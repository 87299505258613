import { State } from "../states-models/store.state.model";
import { combineReducers, ActionReducerMap } from "@ngrx/store";
import { settingsReducer } from "./settings/settings.reducer";
import { routerReducer } from "@ngrx/router-store";
import { userReducer } from "../../../shared/stores/user/reducer/user.reducer";

/**
 * The main store combined reducer.
 */
export const mainReducer: ActionReducerMap<State> = {
  settings: settingsReducer,
  router: routerReducer,
  users: userReducer
};

// export const mainReducerMap: ActionReducerMap<State> = {
//   main: mainReducer
// }
