export const AppConfig = {
  production: false,
  endpointURL: "https://devapi.voverc.com/api",
  blendrApiUrl: 'https://test-developer.voverc.com/api/v1/blendr',
  environment: 'TEST-WEB',
  defaultErrorLevel: 'debug',
  supportPhoneNumber: '+390640048260',
  INTERCOM_ID: 'ne99i0tn',
  links: {
    activateNewAccount: 'https://test-go.voxloud.com/prova_gratis/',
    forgotPassword: 'https://test-controlpanel.voxloud.com/login/#/forgot-password',
    referral: 'https://test-go.voxloud.com/activate_now?referrer_id=',
    controlPanel: 'https://test-controlpanel.voxloud.com/login/#/signin?email=',
    helpDesk: 'https://help.voxloud.com/',
    videoconference: 'https://video.test.voxloud.com'
  },
  supportedLanguages: [
    {
      locale: "it",
      name: "Italiano"
    },
    {
      locale: "en",
      name: "English"
    }
  ],
};
