import { SessionState } from 'sip.js';
import { SessionWrapper } from '../wrappers';
import { IncomingResponse } from 'sip.js/lib/core';

export interface InviteOptions {
  constraints?: MediaTrackConstraints;
  delegates?: {
    onTryingDelegate?: (response:IncomingResponse) => void,
    onProgressDelegate?: (response: IncomingResponse) => void
  }
}


export interface SimpleSessionCallOptions {
  inviteOptions?: InviteOptions;
  stateChangeListener?: (state: SessionState) => void;
}

export interface AcceptInvitationOptions {
  constraints?: MediaTrackConstraints;
}

export interface DisplayName {
  name: CompositeDisplayName;
  number: string;
}

// Used for cases like To *did name* from *number or name*
export interface CompositeDisplayName {
  remote: string;
  local?: string;
}

export interface SessionWrapperDelegates {
  onInitial?: (s: SessionWrapper) => void,
  onEnstablishing?: (s: SessionWrapper) => void,
  onEnstablished?: (s: SessionWrapper) => void,
  onTerminating?: (s: SessionWrapper) => void,
  onTerminated?: (s: SessionWrapper) => void
}

export interface EndCallOptions {
  rejectCode?: number;
}

export enum SIP_STATUS_CODES {
  BUSY_HERE = 486,
  DECLINED = 603
}