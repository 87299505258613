import { Injectable } from '@angular/core';
import { AccountState, AccountTokens, SessionDTO, SipCredentials, SubscriptionLite } from '../states-models';
import { Store, select } from '@ngrx/store';
import {
  saveUsername,
  saveTokens,
  saveSipCredentials,
  logout,
  savePbxAndCompanyIds,
  saveSubscription,
  saveSession,
  saveOutboundNumber,
  saveInboxToken
} from '../actions';

import {
  selectAccountState,
  selectUsername,
  selectJwt,
  selectTokens,
  selectAccessToken,
  selectSipCredentials,
  selectPbxId,
  selectCompanyId,
  selectSubscription,
  selectSession,
  selectIntegrationsEnabled,
  selectInboxToken
} from '../selectors';
import { Integration } from 'voxloud-types/dist/models/integrations';



@Injectable({
  providedIn: 'root'
})
export class AccountFacade {

  account$ = this.store.pipe(select(selectAccountState));
  username$ = this.store.pipe(select(selectUsername));
  tokens$ = this.store.pipe(select(selectTokens));
  accessToken$ = this.store.pipe(select(selectAccessToken));
  pbxId$ = this.store.pipe(select(selectPbxId));
  accountId$ = this.store.pipe(select(selectCompanyId));
  jwtToken$ = this.store.pipe(select(selectJwt));
  sipCredentials$ = this.store.pipe(select(selectSipCredentials));
  subscription$ = this.store.pipe(select(selectSubscription));
  session$ = this.store.pipe(select(selectSession));
  integrationsEnabled$ = this.store.pipe(select(selectIntegrationsEnabled));
  inboxToken$ = this.store.pipe(select(selectInboxToken));

  constructor(private store: Store<AccountState>) { }

  setUsername(username: string) {
    this.store.dispatch(saveUsername(username));
  };

  setPbxAndCompanyIds(pbxId: string,companyId: string) {
    this.store.dispatch(savePbxAndCompanyIds(pbxId, companyId));
  }

  setTokens(tokens: AccountTokens) {
    this.store.dispatch(saveTokens(tokens));
  }

  setSipCredentials(sipCreds: SipCredentials) {
    this.store.dispatch(saveSipCredentials(sipCreds));
  }

  setSubscription(sub: SubscriptionLite) {
    this.store.dispatch(saveSubscription(sub));
  }

  setSession(session: SessionDTO) {
    this.store.dispatch(saveSession(session));
  }

  setOutboundNumber(number: string) {
    this.store.dispatch(saveOutboundNumber(number));
  }

  setInboxToken(token: string) {
    this.store.dispatch(saveInboxToken(token))
  }

  logout() {
    this.store.dispatch(logout());
  }
}
