import { createAction, union } from "@ngrx/store";
import { User } from "../../../models/user.model";

export const saveUsers = createAction(
  '[User] Save users',
  (users: User[]) => ({users})
);

const actions = {
  saveUsers
};

const userActions = union(actions);

export type UserActions = typeof userActions;