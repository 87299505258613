import { Injectable } from "@angular/core";
import { UserStoreState } from "../user.model";
import { Store } from "@ngrx/store";
import { User } from "../../../models/user.model";
import { saveUsers } from "../actions/user.action";
import { selectAllUsers } from "../selectors/user.selector";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserFacade {

  public users$ = this.store.select(selectAllUsers);

  constructor(
    private store: Store<UserStoreState>
  ){}

  public storeUsers(users: User[]){
    this.store.dispatch(saveUsers(users));
  };

  public getAllUsersWhoCanBeSpied(pbxId: number): Observable<User[]> {
    return this.users$.pipe(map((users: User[]) => {
      let res: User[] = [];
      res = users.filter((user: User) => {
        return user.call_spy_callflow?.allowed_user_ids?.some(v => v.id === pbxId);
      });
      return res;
    }));
  }

}