import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, filter, take, timeout } from 'rxjs/operators';
import { Paginated } from 'voxloud-types/dist/models/common';
import { AppConfig } from '../../../../environments/environment';
import { AccountFacade } from '../../../account/store/facade';
import { SessionDTO, SubscriptionLite } from '../../../account/store/states-models';
import { IntegrationBlend } from '../../models/integrations.model';
import { Integration } from 'voxloud-types/dist/models/integrations';

@Injectable()
export class IntegrationsService {
  public companyId: string;
  private userId: string;
  private shortlyDeletedGuid: string[] = [];
  private DEFAULT_PAGE_SIZE: number = 200;

  constructor(
    private http: HttpClient,
    private readonly accountFacade: AccountFacade
  ) {
    accountFacade.subscription$
      .pipe(
        filter(s => ![null,undefined].includes(s)),
        take(1)
      )
      .subscribe((sub: SubscriptionLite) => {
        this.companyId = sub?.company_id;
      });
    accountFacade.session$
      .pipe(
        filter(s => ![null, undefined].includes(s)),
        take(1)
      ).subscribe(
        (session: SessionDTO) => {
          this.userId = session.user_aggregate_id;
        }
      )
  }


  private wasGuidDeleted(guid: string) {
    if (guid) return this.shortlyDeletedGuid.includes(guid);
    return false;
  }

  public getIntegrations(id?: number): Observable<Paginated<Integration>> {
    return this.http.get<Paginated<Integration>>(
      `${AppConfig.endpointURL}/v1/integrations?company_id=${this.companyId ? this.companyId : id}&size=${this.DEFAULT_PAGE_SIZE}`
    );
  }

  public findIntegrationByPlatformName(name: string, companyId?: number): Observable<Paginated<Integration>> {
    return this.http.get<Paginated<Integration>>(
      `${AppConfig.endpointURL}/v1/integrations?platform_name=${name}&size=${this.DEFAULT_PAGE_SIZE}&company_id=${companyId ? companyId : this.companyId}`
    );
  }

  public getRomulusToken() {
    return this.http.get(
      `${AppConfig.endpointURL}/v1/integrations/users/${this.userId}/token?platform_name=ROMULUS`
    )
  }

}
